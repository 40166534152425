import { AnchorLink } from "gatsby-plugin-anchor-links"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import { FaBars } from "@react-icons/all-files/fa/FaBars"

import Lila from "../images/lila-icon-1.png"

const Header = ({ siteTitle }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <header className="flex flex-wrap py-2 text-gray-600 body-font">
        <div className="w-full px-4">
          <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
              <div className="w-full relative flex justify-between lg:w-auto px-4 lg:static lg:block lg:justify-start">
                <AnchorLink
                  to="/"
                  className="font-bold title-font font-medium inline-block mr-4 py-2 whitespace-no-wrap  text-gray-900"
                  href="#pablo"
                >
                  <img
                    src={Lila}
                    alt="lila-makeup"
                    className="inline-block logo-lila"
                  ></img>
                  <span className="font-bold title-font font-medium inline-block ml-4 py-2 whitespace-no-wrap  text-gray-900">
                    {siteTitle}{" "}
                  </span>
                </AnchorLink>

                <button
                  className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                  type="button"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  <FaBars />
                </button>
              </div>
              <div
                className={
                  "lg:flex flex-grow items-center" +
                  (menuOpen ? " flex" : " hidden")
                }
                id="example-navbar-info"
              >
                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto lg:mr-auto">
                  <li className="nav-item lg:mr-4">
                    <AnchorLink
                      to="/"
                      className="px-3 py-2  flex items-center     text-base hover:text-gray-900"
                      onAnchorLinkClick={() => setMenuOpen(!menuOpen)}
                    >
                      Accueil
                    </AnchorLink>
                  </li>
                  <li className="nav-item lg:mr-4">
                    <AnchorLink
                      to="/#services"
                      className="px-3 py-2 flex items-center     text-base hover:text-gray-900"
                      onAnchorLinkClick={() => setMenuOpen(!menuOpen)}
                    >
                      Mes Services
                    </AnchorLink>
                  </li>
                  <li className="nav-item lg:mr-4">
                    <AnchorLink
                      to="/#galerie"
                      className="px-3 py-2 flex items-center     text-base hover:text-gray-900"
                      onAnchorLinkClick={() => setMenuOpen(!menuOpen)}
                    >
                      Galerie
                    </AnchorLink>
                  </li>
                  <li className="nav-item lg:mr-4">
                    <AnchorLink
                      to="/#contact"
                      className="px-3 py-2 flex items-center     text-base hover:text-gray-900"
                      onAnchorLinkClick={() => setMenuOpen(!menuOpen)}
                    >
                      Contact
                    </AnchorLink>
                  </li>
                  <a
                    href="https://www.instagram.com/lila_mua_/"
                    rel="noopener noreferrer"
                    className="hover:text-gray-900 flex items-center text-xl ml-3 md:hidden"
                  >
                    <FaInstagram className="" />
                  </a>
                </ul>
              </div>
              <a
                href="https://www.instagram.com/lila_mua_/"
                rel="noopener noreferrer"
                className="hidden lg:flex hover:text-gray-900"
              >
                <FaInstagram className="md:text-2xl ml-16 mr-16" />
              </a>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
