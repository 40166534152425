import React from "react"

import { Link } from "gatsby"
import Lila from "../images/lila-icon-1.png"

const Footer = ({ siteTitle }) => {
  return (
    <>
      <footer className="text-gray-600 body-font">
        <div className="bg-gray-100">
          <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
            <Link
              to="/"
              className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
            >
              <img
                src={Lila}
                alt="lila-makeup"
                className="w-14 h-14 text-white p-2 bg-gray-100 rounded-full"
                width="55"
              ></img>
            </Link>

            <span className="ml-3 text-xl">{siteTitle} </span>

            <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
              © {new Date().getFullYear()} {siteTitle} — Site Crée par
              <a
                href="https://www.smainrabhi.com/"
                rel="noopener noreferrer"
                className="text-gray-600 ml-1"
                target="_blank"
              >
                Smaïn Rabhi
              </a>
            </p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a
                href="https://www.instagram.com/lila_mua_/"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-3 text-gray-500"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
            </span>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
